<template>
  <v-app>
    <v-main>
    <v-container>
        <v-row class="mt-15 justify-center">
            <v-col cols="12" xl="4" lg="4" md="4" sm="12" >
                
                <v-card class="border-login">
                    <v-card-text>
                        <v-form @submit.prevent="autenticar" :disabled="disabledForm" >
                            <v-row>
                              <v-col cols="12">
                                 <v-img class="mx-auto" contain width="200" :src="require('../../../assets/logo-optimania.png')"  >
                                                                        </v-img>
                              </v-col>
                                <v-col cols="12">
                                  <v-alert class="text-left" type="success" v-model="alertExito" dismissible>
                                      <p class="my-0" v-text="msjRespuesta"></p>
                                  </v-alert>
                                  <v-alert class="text-left" type="error" v-model="alertError" dismissible>
                                      <p v-text="msjRespuesta" class="my-0"></p>
                                      <ul class="my-0" v-for="(errors, index) in listErrors" :key="index">
                                          <li v-text="errors[0]"></li>
                                      </ul>
                                  </v-alert>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field v-model="login.usuario" label="Usuario" prepend-icon="mdi-account-lock" type="text" ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field v-model="login.clave" label="Contraseña" prepend-icon="mdi-lock-question" :append-icon="mostrarClave ? 'mdi-eye' : 'mdi-eye-off'" :type="mostrarClave ? 'text' : 'password'" @click:append="mostrarClave = !mostrarClave"></v-text-field>
                                </v-col>

                                <v-col cols="12" class="text-center">
                                    <v-btn :loading="disabledForm" type="submit"  color="success">
                                        <v-icon left="">mdi-login</v-icon> Ingresar
                                    </v-btn>
                                </v-col>

                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
    </v-main>
  </v-app>
</template>


<script>
export default {

  data() {
    return {
      login:{
        usuario:'',
        clave:''
      },
      mostrarClave:false,
      msjRespuesta:'',
      listErrors: [],
      disabledForm: false,
      alertExito:false,
      alertError: false
      
    }
  },


  methods: {
    autenticar(){
      this.listErrors = [];
      this.disabledForm = true;
      this.alertError = false;
      this.alertExito = false;
      this.axios.post('api/panel/login/autenticar',this.login)
      .then(res => {
        this.alertExito = true;
        this.msjRespuesta = res.data.mensaje;
        this.$session.set('token',res.data.token);
        this.$session.set('usuario',res.data.usuario);
        this.$router.push({name:'inicioPanel'});
      })
      .catch(err => {
        if (err.response.status === 422) {
            this.alertError = true;
            this.listErrors = Object.values(err.response.data.errors);
        }else if(err.response.status === 400){
            this.alertError = true;
            this.msjRespuesta = err.response.data.mensaje;
        }

      })
      .then(() =>{
        this.disabledForm = false;
      })
    }
  },
  created() {
    console.log();
  },
    
}
</script>
<style scoped>
.border-login{
    border: 1px solid rgba(35, 201, 243, 0.884);
}
</style>